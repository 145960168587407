<template>
  <div class="deviceShowBackGround">
    <!-- ---------------------------------NAVBAR------------------------------- -->

    <div id="appbar">
      <text @click="navToHome" class="title" id="hiddenTitle">
        &nbsp; &nbsp; &nbsp; &nbsp;DDC IoT Platform</text
      >

      <div class="container">
        <div class="item">
          <img :src="require('../../assets/logo2.png')" />
        </div>
      </div>

      <button
        type="button"
        id="navButtonHomeDeviceShow"
        class="btn btn-success"
        @click="navToHome"
      >
        <i class="fa-solid fa-house"></i> <text class="navText">Home</text>
      </button>
      <button
        type="button"
        id="navButtonDomainsDeviceShow"
        class="btn btn-success"
        @click="navToDomains"
      >
        <i class="fa-solid fa-layer-group"></i>
        <text class="navText"> Domains</text>
      </button>
      <button
        type="button"
        id="navButtonActiveDeviceShow"
        class="btn btn-success"
        @click="navToDevices"
      >
        <i class="fas fa-hdd"></i> <text class="navText">Devices</text>
      </button>
      <button
        type="button"
        id="navButtonUsersDeviceShow"
        class="btn btn-success"
        @click="navToUsers"
      >
        <i class="fa-solid fa-users"></i> <text class="navText">Users</text>
      </button>
      <router-link to="/domains" class="Router-link"
        ><i class="fas fa-layer-group"></i>
      </router-link>
      <router-link to="/devices" class="Router-link"
        ><i class="fas fa-chalkboard"></i
      ></router-link>
      <router-link to="/users" class="Router-link"
        ><i class="fas fa-user-friends"></i> Users</router-link
      >
      <div id="profileIcon">
        <button
          type="button"
          class="btn btn-success"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          id="logoutButtonDeviceShow"
          @click="profilename"
        >
          <i class="fas fa-user-alt" id="pIcon"></i>&ensp;
          <label class="usernameText">{{ this.user }} </label>&ensp;

          <i class="fa-solid fa-caret-down" id="pIcon"></i> &nbsp;
        </button>
        <div class="dropdown-menu">
          <a @click="navToSettings" class="dropdown-item" id="blockedContent"
            >Settings <i class="fas fa-cogs" id="settingsIcon"></i
          ></a>
          <a @click="logout" class="dropdown-item"
            >Logout <i class="fas fa-sign-out-alt" id="logoutIcon"></i
          ></a>
        </div>
      </div>
    </div>
    <!-- ---------------------------------Devices page after navbar------------------------------- -->
    <div class="deviceShow">
      <!-- ---------------------------------TOP CARD------------------------------- -->
      <div class="topCard">
        <div class="deviceHeader">
          <div class="deviceHeaderPlace">
            <div class="headerText"><b>Device name:</b> {{ name }}</div>
            <h5 class="headerText"><b>Physical ID:</b> {{ physicalID }}</h5>
            <h5 class="headerText"><b>uuid: </b>{{ uuid }}</h5>
            <br />
            <button
              type="button"
              class="btn btn-primary"
              @click="csvExport(errorData, errorCode)"
              id="exportButtonId"
            >
              <i class="fas fa-file-csv"></i> Export Data
            </button>
          </div>
        </div>

        <br />

        <!-- ---------------------------------GPS & SIGNAL& BATTERY CARD------------------------------- -->
        <!-- <div id="gpsDevinfo">
          <div id="lastCoords">
            <div>Last GPS Coordinates</div>
            <h5><b>Lattitude:</b> {{ currentLat }}</h5>
            <h5><b>Longitude:</b> {{ currentLon }}</h5>
            <h5><b>Altitude:</b> {{ currentAlt }}</h5>
          </div>
        </div> -->

        <!-- ---------------------------------SIGNAL & BATTERY------------------------------- -->

        <div id="deviceInfo">
          <div>Battery and Signal</div>
          <h5>Battery level:</h5>
          <div class="battery">
            <div class="loading-bar m-1">
              <p id="percentageTextBattery">{{ this.currentBattery }}%</p>
              <div
                class="percentage"
                id="batteryId"
                :style="{ width: this.currentBattery + '%' }"
              ></div>
            </div>
          </div>
          <h5>Network signal strength:</h5>
          <div class="battery">
            <div class="loading-bar m-1">
              <p id="percentageTextSignal">{{ this.currentSignal }}%</p>
              <div
                class="networkPercentage"
                id="signalId"
                :style="{ width: this.currentSignal + '%' }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <!-- ---------------------------------EVERYTHING ELSE CONTAINER------------------------------- -->
      <div class="containAll">
        <!--<button type="button" class="btn btn-primary" id="showThis" @click="hideActionsFunction()">Show Actions <i class="fa-solid fa-eye"></i></button>-->

        <div class="outerActionBar" id="hideThis">
          <div class="outerActionBarAll">
            <div class="btn-group p-2">
              <button
                id="timeZoneId"
                type="button"
                class="btn btn-success dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Time Zone: {{ this.timeZoneValue + ":00" }}
              </button>
              <div class="dropdown-menu scrollable-menu" id="timeAndPage">
                <a
                  v-for="i in 26"
                  :key="i"
                  class="dropdown-item"
                  @click="setTimeZone(i)"
                  >{{ timeZones[i][0] }}</a
                >
              </div>
            </div>

            <div class="btn-group p-2">
              <button
                type="button"
                id="pageSizeButton"
                class="btn btn-success dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Row number: {{ this.pageSize }}
              </button>
              <div class="dropdown-menu" id="timeAndPage">
                <a
                  @click="
                    this.pageSize = 10;
                    this.page = 0;
                    this.pageNum = 1;
                    refreshactualPage();
                  "
                  class="dropdown-item"
                  >10</a
                >
                <a
                  class="dropdown-item"
                  @click="
                    this.pageSize = 20;
                    this.page = 0;
                    this.pageNum = 1;
                    refreshactualPage();
                  "
                  >20</a
                >
                <a
                  class="dropdown-item"
                  @click="
                    this.pageSize = 50;
                    this.page = 0;
                    this.pageNum = 1;
                    refreshactualPage();
                  "
                  >50</a
                >
                <a
                  class="dropdown-item"
                  @click="
                    this.pageSize = 100;
                    this.page = 0;
                    this.pageNum = 1;
                    refreshactualPage();
                  "
                  >100</a
                >
                <a
                  class="dropdown-item"
                  @click="
                    this.pageSize = 200;
                    this.page = 0;
                    this.pageNum = 1;
                    refreshactualPage();
                  "
                  >200</a
                >
              </div>
            </div>

            <label id="refreshLabel">Refresh:</label>

            <label class="refreshSwitch">
              <input
                type="checkbox"
                id="refreshCheck"
                @click="changeRefreshSlider()"
              />
              <span class="slider round" id="refreshSlider"></span>
            </label>
            <label class="followLabel">Follow:</label>
            <label class="followSwitch">
              <input type="checkbox" id="followCheck" @click="setFollow()" />
              <span
                class="slider round"
                id="followSlider"
                @click="changeFollowSlider()"
              ></span>
            </label>
            <label class="errorsLabel">Errors:</label>
            <label class="errorsSwitch">
              <input
                type="checkbox"
                id="refreshCheck"
                @click="
                  changeErrorSlider(),
                    changeErrorValue(),
                    clearErrorFilter(),
                    setErrorFilter()
                "
              />
              <span class="slider round" id="errorSlider"></span>
            </label>
            <label class="filterLabel">Filter:</label>
            <label class="filterSwitch">
              <input
                type="checkbox"
                id="filterCheck"
                @click="changeFilterSlider()"
              />
              <span class="slider round" id="filterSlider"></span>
            </label>
            <label class="errorInputLabel">Error:</label>
            <input
              type="text"
              v-if="errorValue"
              class="form-control"
              placeholder="Error code"
              id="errorInput"
              @keydown="isEnter()"
            />
            <button
              id="errorButtonId"
              class="errorButton"
              @click="setErrorFilter()"
              v-if="errorValue"
            >
              <i id="filterIcon" class="fas fa-filter"></i>
            </button>
            <div class="dateStuff1">
              <label id="startText" v-if="filterValue">Start date(UTC):</label>
              <input type="datetime-local" v-if="filterValue" id="startDate" />
            </div>
            <div class="dateStuff2">
              <label id="endText" v-if="filterValue">End date (UTC):</label>
              <input
                type="datetime-local"
                v-if="filterValue"
                id="endDate"
                v-model="toDate"
              />
              <button
                class="dateButton"
                v-if="filterValue"
                @click="refreshactualPage"
              >
                Apply
              </button>
            </div>

            <!--   <label class="slideLabel">Show route:</label>
          <label class="switch">
            <input type="checkbox" @click="showRoute()" />
            <span
              class="slider round"
              id="routeSlider"
              @click="changeRouteSlider()"
            ></span>
          </label> -->

            <!-- <button type="button" class="btn btn-primary" id="hideActionsButton" @click="hideActionsFunction()">Hide actions <i class="fa-solid fa-xmark"></i></button> -->
            <div class="col-sm-5 mt-3">
              <div class="dropdown dropstart">
                <i
                  class="fa-solid fa-circle-info"
                  type="button"
                  id="infoButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div
                  class="dropdown-menu"
                  aria-labelledby="infoButton"
                  id="infoDropdown"
                >
                  <p>
                    <b>Time zone:</b>
                    You can change the time which is shown in the table bellow.
                  </p>
                  <p>
                    <b> Row number: </b>You can change the number of rows in the
                    table below.
                  </p>
                  <p>
                    <b> Refresh:</b> If toggled refreshes table content every 5
                    seconds
                  </p>
                  <p>
                    <b> Follow: </b>If toggled the map jumps to the last
                    coordination among the listed data of the device
                  </p>
                  <p>
                    <b> Errors:</b> If toggled you can filter to error messages
                    or error codes
                  </p>
                  <p>
                    <b> Filter: </b>If toggled you can filter to start and end
                    date in the data table
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <!-- ---------------------------------TABLE & MAP------------------------------- -->
        <div class="row justify-content-center" id="mapAndTable ">
          <div class="tableCard" id="tableCardId">
            <!-- ---------------------------------TABLE NAVIGATION BUTTONS------------------------------- -->

            <!-- ---------------------------------MOBILE PAGING------------------------------- -->

            <nav aria-label="Page navigation example" class="pagingBarBottom">
              <div class="actionBar">
                <ul class="pagination">
                  <!-- ---------------------------------FIRST PAGE------------------------------- -->
                  <li class="page-item">
                    <a
                      class="page-link"
                      @click="
                        this.page = 0;
                        this.pageNum = 1;
                        refreshactualPage();
                      "
                      id="pageLink1"
                      >First</a
                    >
                  </li>
                  <!-- ---------------------------------PREVIOUS PAGE------------------------------- -->
                  <li id="pageDownButton" class="page-item">
                    <a class="page-link" @click="previousPage()">Previous</a>
                  </li>
                  <!-- ---------------------------------PAGENUMBER------------------------------- -->
                  <li class="page-item">
                    <a class="page-link">{{ pageNum }}</a>
                  </li>
                  <!--  <li class="page-item">
                    <a class="page-link">...</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" @click="jumpLast()">{{
                      Math.ceil(this.pageCount)
                    }}</a>
                  </li> -->
                  <!-- ---------------------------------NEXT PAGE------------------------------- -->
                  <li id="pageUpButton" class="page-item" disabled>
                    <a class="page-link" @click="nextPage()">Next</a>
                  </li>
                  <!-- ---------------------------------LAST PAGE------------------------------- -->
                  <li class="page-item">
                    <a class="page-link" id="pageLink2" @click="jumpLast()"
                      >Last</a
                    >
                  </li>
                </ul>
              </div>
            </nav>
            <br />
            <!-- ---------------------------------DATA TABLE------------------------------- -->
            <div class="table-wrapper-scroll-y my-custom-scrollbar">
              <table class="table table-dark" id="coordTable">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <input
                        class="form-check-input flexCheckDefault"
                        type="checkbox"
                        id="checkAllBoxes"
                        checked
                        @change="checkAllBoxes($event.target.checked)"
                      />
                    </th>
                    <th style="text-align: center">Num</th>
                    <th style="text-align: center" scope="col">Timestamp</th>
                    <!-- 
                    <th style="text-align: center" scope="col">Lon</th>
                    <th style="text-align: center" scope="col">Alt</th>
                    <th style="text-align: center" scope="col">Date</th>
                    <th style="text-align: center" scope="col">Time</th> -->
                    <th scope="col" v-if="errorValue">Error</th>
                  </tr>
                </thead>
                <!-- --------------------------------------TABLE ROW---------------------------------- -->
                <!-- <div> -->
                <tbody v-for="(item, index) in this.num" :key="item.timestamp">
                  <tr class="coordTableRow" :id="'coordTableRow' + index">
                    <!-- ---------------------------------DROPDOWN DATA------------------------------- -->
                    <td @click="toggleRows(index)">
                      <i class="fas fa-chevron-down" :id="'downArrowIcon'+index"></i>
                    </td>
                    <!-- ---------------------------------CHECKBOX------------------------------- -->
                    <td>
                      <input
                        class="form-check-input flexCheckDefault"
                        type="checkbox"
                        :id="'flexCheckDefault' + index"
                        checked
                        @click="refreshactualPage()"
                      />
                    </td>
                    <!-- --------------------------------TABLE CONTENT------------------------------- -->

                    <td style="text-align: center">
                      {{
                        index +
                        1 +
                        this.pageNum * this.pageSize -
                        this.pageSize
                      }}.
                    </td>
                    <td style="text-align: center">
                      {{ item.timestamp }}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr
                    :id="'coordTableRow' + index + 'header'"
                    style="display: none"
                  >
                    <th></th>
                    <th></th>
                    <th style="text-align: center">Name</th>
                    <th style="text-align: center">Mac</th>
                    <th style="text-align: center">RSSI</th>
                    <th style="text-align: center">Timestamp</th>
                  </tr>
                  <tr
                    v-for="(recordItem, recordIndex) in this.num[index].records"
                    :key="recordItem.kind"
                    :id="'coordTableRow' + index + recordIndex"
                    style="display: none"
                    class="coordTableRow"
                  >
                    <td></td>
                    <td></td>
                    <td style="text-align: center">{{ JSON.parse(JSON.parse(recordItem.data)).name }}</td>
                    <td style="text-align: center">
                      {{ JSON.parse(JSON.parse(recordItem.data)).mac}}
                    </td>
                    <td style="text-align: center">
                      {{ JSON.parse(JSON.parse(recordItem.data)).rssi}}
                    </td>
                    <td style="text-align: center">
                      {{ JSON.parse(JSON.parse(recordItem.data)).timestamp }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- ---------------------------------PAGINATION AT BOTTOM------------------------------- -->
            <nav aria-label="Page navigation example" class="pagingBarBottom">
              <ul class="pagination">
                <!-----------------------------------FIRST PAGE------------------------------- -->
                <li class="page-item">
                  <a
                    class="page-link"
                    @click="
                      this.page = 0;
                      this.pageNum = 1;
                      refreshactualPage();
                    "
                    id="pageLink1"
                    >First</a
                  >
                </li>
                <!-----------------------------------PREVIOUS PAGE------------------------------- -->
                <li id="pageDownButton" class="page-item">
                  <a class="page-link" @click="previousPage()">Previous</a>
                </li>
                <!-----------------------------------PAGENUMBER------------------------------- -->
                <li class="page-item">
                  <a class="page-link">{{ pageNum }}</a>
                </li>
                <!--  <li class="page-item">
                  <a class="page-link">...</a>
                </li>
                <li class="page-item">
                  <a class="page-link" @click="jumpLast()">{{
                    Math.ceil(this.pageCount)
                  }}</a>
                </li> -->
                <!-----------------------------------NEXT PAGE------------------------------- -->
                <li id="pageUpButton" class="page-item" disabled>
                  <a class="page-link" @click="nextPage()">Next</a>
                </li>
                <!-----------------------------------LAST PAGE------------------------------- -->
                <li class="page-item">
                  <a class="page-link" id="pageLink2" @click="jumpLast()"
                    >Last</a
                  >
                </li>
              </ul>
            </nav>
          </div>
          <!-- <div class="map col-5" id="mapContainer">
            <div id="mapid" class="h-full z-10"></div>
          </div> -->
        </div>
        <br />
      </div>
    </div>
    <div class="spinner-container" id="spinner">
      <atom-spinner :animation-duration="1000" :size="120" :color="'#42ba85'" />
    </div>
  </div>
</template>

<script>
import ddcIotDataProvider from "../../utils/ddcIotDataProvider";
import ddcIotAuthProvider from "../../utils/ddcIotAuthProvider.js";

import { AtomSpinner } from "epic-spinners";

const apiUrl =
  process.env.VUE_APP_API_URL || "https://iot.ddc.sze.hu/external_api";
const authProvider = ddcIotAuthProvider(apiUrl);
const dataProvider = ddcIotDataProvider(apiUrl);
import L from "leaflet";

export default {
  components: {
    AtomSpinner,
  },
  methods: {
    toggleRows(numIndex) {
      if (
        document.getElementById("coordTableRow" + numIndex + "header").style
          .display == "none"
          
      ) {
        document.getElementById(
          "coordTableRow" + numIndex + "header"
        ).style.display = "";
        document.getElementById("downArrowIcon" + numIndex).classList.remove("fa-chevron-down")

        document.getElementById("downArrowIcon" + numIndex).classList.add("fa-chevron-up")
      } else {
        document.getElementById(
          "coordTableRow" + numIndex + "header"
        ).style.display = "none";
        document.getElementById("downArrowIcon" + numIndex).classList.remove("fa-chevron-up")

        document.getElementById("downArrowIcon" + numIndex).classList.add("fa-chevron-down")
         
      }
      for (let index = 0; index < this.num[numIndex].records.length; index++) {
        //console.log(document.getElementById("coordTableRow" + numIndex+index).style.display)
        if (
          document.getElementById("coordTableRow" + numIndex + index).style
            .display == "" ||
          document.getElementById("coordTableRow" + numIndex + index).style
            .display == "block"
        ) {
          document.getElementById(
            "coordTableRow" + numIndex + index
          ).style.display = "none";
        } else {
          document.getElementById(
            "coordTableRow" + numIndex + index
          ).style.display = "";
        }
      }
    },
    //-------------------------------CHECKBOX---------------------------------------------------
    checkAllBoxes(value) {
      if (value) {
        for (let index = 0; index < this.num.length; index++) {
          document.getElementById("flexCheckDefault" + index).checked = true;
        }
      } else {
        for (let index = 0; index < this.num.length; index++) {
          document.getElementById("flexCheckDefault" + index).checked = false;
        }
      }
      this.refreshactualPage();
    },
    isEnter() {
      if (event.keyCode == 13) {
        document.getElementById("errorButtonId").click();
      }
    },
    //--------------------------------EXPORT TO EXCEL-------------------------------------------
    async csvExport(errorData, errorCode) {
      let oldName = document.getElementById("exportButtonId").innerHTML;
      alert("Starting export... Click OK and wait until it's done.");

      document.getElementById("exportButtonId").innerText = " Exporting...";
      document
        .getElementById("exportButtonId")
        .setAttribute("disabled", "disabled");
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "Device name:;" + this.name + "\n";
      csvContent += "Physical ID:;" + this.physicalID + "\n";
      csvContent += "UUID:;" + this.uuid + "\n";
      csvContent += "\n";
      csvContent +=
        "Time;Latitude;Longitude;Altitude;Battery (%);Signal Strength (%);ErrorCode;Error Msg;\n";
      let params = {
        id: this.uuid,
      };

      let db = await dataProvider.getStatusListCount("devices", params);
      let pagenum = db.data / 400;
      for (let index = 0; index < Math.ceil(pagenum); index++) {
        let res = await dataProvider.getStatusList(
          "devices",
          this.uuid,
          400,
          index
        );
        res.data.forEach((element, index) => {
          let indexes = this.searchIndex(element);
          let long;
          let lati;
          let alti;
          let battery;
          let signal;
          if (indexes.gpsIndex != undefined) {
            long = JSON.parse(element.records[indexes.gpsIndex].data).longitude;
            lati = JSON.parse(element.records[indexes.gpsIndex].data).latitude;
            alti = JSON.parse(element.records[indexes.gpsIndex].data).altitude;
          } else {
            long = "";
            lati = "";
            alti = "";
          }
          if (indexes.batteryIndex != undefined) {
            battery = JSON.parse(element.records[indexes.batteryIndex].data)
              .level.value;
          } else {
            battery = "";
          }
          if (indexes.networkIndex != undefined) {
            signal = JSON.parse(element.records[indexes.networkIndex].data)
              .signal_strength.value;
          } else {
            signal = "";
          }
          csvContent +=
            element.timestamp +
            ";" +
            long +
            ";" +
            lati +
            ";" +
            alti +
            ";" +
            battery +
            ";" +
            signal +
            ";" +
            errorCode[index] +
            ";" +
            errorData[index] +
            ";" +
            "\n";
        });
      }
      //Promise.all(promis).then(() => {
      alert("done");
      document.getElementById("exportButtonId").innerHTML = oldName;
      document.getElementById("exportButtonId").removeAttribute("disabled");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
      //});
    },
    //--------------------------------NAVBAR METHODS-------------------------------------------
    logout() {
      authProvider.logout();
      this.loggedIn = false;
      this.$router.push("/Login");
      localStorage.setItem("username", "");
    },
    profilename() {},

    navToDevices() {
      this.$router.push("/devices");
    },

    navToHome() {
      this.$router.push("/");
    },

    navToDomains() {
      this.$router.push("/domains");
    },

    navToUsers() {
      this.$router.push("/users");
    },

    navToSettings() {
      this.$router.push("/settings");
    },
    //--------------------------------ERROR FILTER-------------------------------------------
    setErrorFilter() {
      if (document.getElementById("errorInput")) {
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("errorInput");
        filter = input.value.toUpperCase() + " ";
        table = document.getElementById("coordTable");
        tr = table.getElementsByTagName("tr");

        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td")[8];
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }
        }
      }
    },

    changeErrorValue() {
      if (this.errorValue == true) {
        this.errorValue = false;
      } else {
        this.errorValue = true;
      }
    },
    ////////-----------------------------------------------BATTERY & SIGNAL COLOR CHANGE----------------------------------------------------------
    colorChange() {
      if (this.currentBattery != null) {
        if (this.currentBattery >= 50) {
          document.getElementById("batteryId").style.backgroundColor =
            "#42ba85";
        } else if (this.currentBattery <= 49 && this.currentBattery >= 20) {
          document.getElementById("batteryId").style.backgroundColor =
            "#f7c600";
        } else if (this.currentBattery <= 19) {
          document.getElementById("batteryId").style.backgroundColor = "red";
        }
        if (this.currentSignal >= 50) {
          document.getElementById("signalId").style.backgroundColor = "#42ba85";
        } else if (this.currentSignal <= 49 && this.currentSignal >= 20) {
          document.getElementById("signalId").style.backgroundColor = "#f7c600";
        } else if (this.currentSignal <= 19) {
          document.getElementById("signalId").style.backgroundColor = "red";
        }
        if (this.battery.length > 0) {
          this.battery.forEach((element, index) => {
            if (this.battery[index] >= 50) {
              document.getElementById(
                "batteryId" + index
              ).style.backgroundColor = "#42ba85";
            } else if (this.battery[index] <= 49 && this.battery[index] >= 20) {
              document.getElementById(
                "batteryId" + index
              ).style.backgroundColor = "#f7c600";
            } else if (this.battery[index] <= 19) {
              document.getElementById(
                "batteryId" + index
              ).style.backgroundColor = "red";
            }
            if (this.signal[index] >= 50) {
              document.getElementById(
                "signalId" + index
              ).style.backgroundColor = "#42ba85";
            } else if (this.signal[index] <= 49 && this.signal[index] >= 20) {
              document.getElementById(
                "signalId" + index
              ).style.backgroundColor = "#f7c600";
            } else if (this.signal[index] <= 19) {
              document.getElementById(
                "signalId" + index
              ).style.backgroundColor = "red";
            }
          });
        }
      }
    },
    //////////-----------------------------------------------------TIMEZONE CHANGE----------------------------------------------------------------------
    setTimeZone(index) {
      let value = 0;
      if (JSON.stringify(this.timeZones[index]).slice(14, 15) == "]") {
        value = JSON.parse(JSON.stringify(this.timeZones[index]).slice(13, 14));
      } else {
        value = parseInt(
          JSON.stringify(this.timeZones[index]).slice(13, 16),
          10
        );
      }
      this.timeZoneValue = value;
    },
    Time(index) {
      let minusT = "";
      minusT = JSON.stringify(this.time[index]).slice(1, 24).replace("T", " ");
      let timeValue = 0;
      timeValue = parseInt(minusT.slice(10, 13), 10);
      let addedValue = 0;
      let addedDay = 0;
      addedValue = timeValue + this.timeZoneValue;

      if (addedValue >= 24) {
        addedValue = 0;
        addedDay = 1;
      } else if (addedValue < 0) {
        addedValue = addedValue + 24;
        addedDay = -1;
      }
      let actualTime = "";
      actualTime = minusT.replace(minusT.slice(10, 13), " " + addedValue);
      let actualTimePlusDay = "";
      let day = " ";
      day = actualTime.slice(8, 11);
      let dayValue = 0;
      dayValue = parseInt(day) + addedDay;
      if (dayValue <= 9) {
        dayValue = "0" + dayValue;
      }
      actualTimePlusDay = actualTime.replace(
        actualTime.slice(8, 11),
        dayValue + " "
      );
      if (actualTimePlusDay.length == 22) {
        return actualTimePlusDay.slice(0, 18);
      }
      return actualTimePlusDay.slice(0, 19);
    },
    /////-----------------------------------------------------------MAP COLOR CHANGE, ROUTE, MOVE MAP--------------------------------------------------
    async colorMarker(markerIndex) {
      //this.defaultIcon = this.markers[index].getIcon();

      this.markers[markerIndex].setIcon(this.greenIcon);
      await new Promise((resolve) => setTimeout(resolve, 500));

      this.markers[markerIndex].setIcon(this.blueIcon);
      await new Promise((resolve) => setTimeout(resolve, 500));
      this.markers[markerIndex].setIcon(this.greenIcon);
      await new Promise((resolve) => setTimeout(resolve, 500));

      this.markers[markerIndex].setIcon(this.blueIcon);
      await new Promise((resolve) => setTimeout(resolve, 500));
    },

    showRoute() {
      let pLineGroup = L.layerGroup();
      for (let i = 0; i < this.lat.length; i++) {
        this.latlngs.push(this.markers[i].getLatLng());
      }

      if (this.routeValue == false) {
        pLineGroup.addLayer(L.polyline(this.latlngs, { color: "#42ba85" }));
        pLineGroup.addTo(this.mymap);

        this.routeValue = true;
      } else if (this.routeValue == false) {
        for (let i = 0; i < pLineGroup.length; i++) {
          this.mymap.removeLayer(pLineGroup);
        }

        this.routeValue = false;
      }
    },

    mapJumpOnClick(index) {
      this.mymap.panTo([this.lat[index], this.lon[index]]);
      this.colorMarker(index);
    },
    /////////--------------------------------------------TOGGLE METHODS--------------------------------------------------
    async refreshTable() {
      if (this.pushed == 1) {
        this.pushed = 0;
      } else {
        this.pushed = 1;
      }
      while (this.pushed == 1) {
        this.refreshactualPage();
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    },
    changeErrorSlider() {
      if (this.errorSlide == 0) {
        this.errorSlide = 1;
        document.getElementById("errorSlider").style.backgroundColor =
          "#42ba85";
        this.refreshactualPage();
      } else {
        this.errorSlide = 0;
        document.getElementById("errorSlider").style.backgroundColor =
          "#2a323d";
        this.refreshactualPage();
      }
    },

    changeFilterSlider() {
      if (this.filterSlide == 0) {
        this.filterSlide = 1;
        document.getElementById("filterSlider").style.backgroundColor =
          "#42ba85";
        this.filterValue = !this.filterValue;

        this.refreshactualPage();
      } else {
        this.filterSlide = 0;
        document.getElementById("filterSlider").style.backgroundColor =
          "#2a323d";
        this.filterValue = !this.filterValue;
        this.fromDate = undefined;
        this.toDate = undefined;
        this.refreshactualPage();
      }
    },
    changeRefreshSlider() {
      if (this.refreshSlide == 0) {
        this.refreshSlide = 1;
        document.getElementById("refreshSlider").style.backgroundColor =
          "#42ba85";
        this.refreshTable();
      } else {
        this.refreshSlide = 0;
        document.getElementById("refreshSlider").style.backgroundColor =
          "#2a323d";
        this.refreshTable();
      }
    },

    changeFollowSlider() {
      if (this.follow == false) {
        this.follow = true;
        document.getElementById("followSlider").style.backgroundColor =
          "#42ba85";
        this.mymap.panTo([this.lat[0], this.lon[0]]);
      } else {
        this.follow = false;
        document.getElementById("followSlider").style.backgroundColor =
          "#2a323d";
      }
    },
    /////////-------------------------------------TABLE NAVIGATION METHODS------------------------------------------------
    async jumpLast() {
      document.getElementById("spinner").style.display = "block";
      let actualPage = this.page;
      let array = await dataProvider.getJurnalList(
        "devices",
        this.uuid,
        this.pageSize,
        actualPage
      );
      do {
        array = await dataProvider.getJurnalList(
          "devices",
          this.uuid,
          this.pageSize,
          actualPage
        );
        actualPage++;
      } while (array.data.length > 0);
      this.page = actualPage - 2;
      this.pageNum = actualPage - 1;
      this.refreshactualPage();

      /* let params = {
        id: this.uuid,
      };
       dataProvider
        .getStatusListCount("devices", params, this.fromDate, this.toDate)
        .then((res) => {
          this.page = Math.floor(res.data / this.pageSize);
          this.pageNum = Math.ceil(res.data / this.pageSize);
          if (this.page == this.pageNum) {
            this.page--;
          }
        }); */
    },
    previousPage() {
      document.getElementById("spinner").style.display = "block";
      if (this.pageNum > 1) {
        this.pageNum--;
        this.page--;
        this.refreshactualPage();
      }
    },
    nextPage() {
      document.getElementById("spinner").style.display = "block";
      if (this.pageNum != this.pageCount) {
        this.pageNum++;
        this.page++;
        this.refreshactualPage();
      }
    },

    /*
    changeRouteSlider() {
      if (this.routeSlide == 0) {
        this.routeSlide = 1;
        document.getElementById("routeSlider").style.backgroundColor =
          "#42ba85";
      } else {
        this.routeSlide = 0;
        document.getElementById("routeSlider").style.backgroundColor =
          "#2a323d";
      }
    },
    */

    clearErrorFilter() {
      if (document.getElementById("errorInput")) {
        document.getElementById("errorInput").value = "";
      }
      this.setErrorFilter();
    },
    ////----------------------------------------SEARCH METHODS----------------------------------------------
    searchIndex(array) {
      let gpsIndex;
      let networkIndex;
      let errorIndex;
      let batteryIndex;
      let lat, lon, alt;
      for (let i in array.records) {
        if (array.records[i].kind == "@gps_location") {
          gpsIndex = i;
        }
        if (array.records[i].kind == "@battery_info") {
          batteryIndex = i;
        }
        if (array.records[i].kind == "@network_info") {
          networkIndex = i;
        }
        if (array.records[i].kind == "@error") {
          errorIndex = i;
        }
        if (array.records[i].kind == "GPS_latitude") {
          lat = i;
        }
        if (array.records[i].kind == "GPS_longitude") {
          lon = i;
        }
        if (array.records[i].kind == "GPS_altitude") {
          alt = i;
        }
      }
      return {
        gpsIndex,
        batteryIndex,
        networkIndex,
        errorIndex,
        lat,
        lon,
        alt,
      };
    },
    //////////////////////--------------------------------------REFRESH ACTUAL TABLE PAGE-------------------------------------------------------------
    async refreshactualPage() {
      document.getElementById("spinner").style.display = "block";
      /* let params = {
        uuid: localStorage.getItem("deviceShow"),
        id: localStorage.getItem("deviceShow"),
      }; */

      //-------------------------------FILTERED FOR DATE-------------------------------------------------
      if (this.filterValue) {
        if (document.getElementById("startDate")) {
          if (
            document.getElementById("startDate").value != undefined &&
            document.getElementById("startDate").value != null &&
            document.getElementById("startDate").value != ""
          ) {
            this.fromDate =
              document.getElementById("startDate").value + ":00.000Z";
          }
        } else {
          this.fromDate = undefined;
        }
        if (document.getElementById("endDate")) {
          if (
            document.getElementById("endDate").value != undefined &&
            document.getElementById("endDate").value != null &&
            document.getElementById("endDate").value != ""
          ) {
            this.toDate = document.getElementById("endDate").value;
            this.savedToDate =
              document.getElementById("endDate").value + ":00.000Z";
          }
        } else {
          this.savedToDate = undefined;
        }
        let statusData = await dataProvider.getStatusList(
          "devices",
          this.uuid,
          1,
          0
        );
        if (statusData.length > 0) {
          this.currentAlt = JSON.parse(
            statusData.data[0].records[
              this.searchIndex(statusData.data[0]).gpsIndex
            ].data
          ).altitude;
          this.currentLat = JSON.parse(
            statusData.data[0].records[
              this.searchIndex(statusData.data[0]).gpsIndex
            ].data
          ).latitude;
          this.currentLon = JSON.parse(
            statusData.data[0].records[
              this.searchIndex(statusData.data[0]).gpsIndex
            ].data
          ).longitude;

          this.currentBattery = JSON.parse(
            statusData.data[0].records[
              this.searchIndex(statusData.data[0]).batteryIndex
            ].data
          ).level.value;
          this.currentSignal = JSON.parse(
            statusData.data[0].records[
              this.searchIndex(statusData.data[0]).networkIndex
            ].data
          ).signal_strength.value;
        }

        /* let res = await dataProvider.getStatusListCount(
          "devices",
          params,
          this.fromDate,
          this.toDate
        );
        this.pageCount = Math.ceil(res.data / this.pageSize); */
        let journal = await dataProvider.getJurnalList(
          "devices",
          this.uuid,
          this.pageSize,
          this.page,
          this.fromDate,
          this.savedToDate
        );

        this.num = journal.data;
        this.alt = [];
        this.lon = [];
        this.lat = [];
        this.time = [];
        this.errorCode = [];
        this.errorData = [];
        this.battery = [];
        this.signal = [];
        journal.data.forEach((elem) => {
          let indexes = this.searchIndex(elem);
          if (indexes.lat != undefined) {
            this.alt.push(String(JSON.parse(elem.records[indexes.alt].data)));
            this.lon.push(String(JSON.parse(elem.records[indexes.lon].data)));
            this.lat.push(String(JSON.parse(elem.records[indexes.lat].data)));
          } else {
            this.alt.push("");
            this.lon.push("");
            this.lat.push("");
          }
          if (elem.timestamp != undefined) {
            this.time.push(elem.timestamp);
          } else {
            this.time.push("");
          }
          if (indexes.batteryIndex != undefined) {
            this.battery.push(
              JSON.parse(elem.records[indexes.batteryIndex].data).level.value
            );
          } else {
            this.battery.push("");
          }
          if (indexes.networkIndex != undefined) {
            this.signal.push(
              JSON.parse(elem.records[indexes.networkIndex].data)
                .signal_strength.value
            );
            localStorage.setItem("currentSignal", this.signal[0]);
          } else {
            this.signal.push("");
          }

          if (indexes.errorIndex != undefined) {
            this.errorData.push(
              JSON.parse(elem.records[indexes.errorIndex].data).message
            );
            this.errorCode.push(
              JSON.parse(elem.records[indexes.errorIndex].data).code
            );
          } else {
            this.errorData.push("");
            this.errorCode.push("");
          }
        });

        //----------------------------------------MARKERS--------------------------------------------------
        /*  this.markers.forEach((element, index) => {
          this.mymap.removeLayer(this.markers[index]);
        });
        this.markers = [];
        this.lat.forEach((element, index) => {
          if (
            document.getElementById("flexCheckDefault" + index).checked == true
          ) {
          this.markers[index]=
            L.marker([this.lat[index], this.lon[index]]).bindPopup(
              "latitude: " +
                this.lat[index] +
                "\n longitude: " +
                this.lon[index] +
                "\n time: " +
                this.time[index]
            )
          
          this.mymap.addLayer(this.markers[(element, index)]);
          }
        });

        this.colorChange();
        document.getElementById("spinner").style.display = "none";
        if (this.follow) {
          this.mymap.panTo([this.lat[0], this.lon[0]]);
        } */
      } //-----------------------------------------------NOT FILTERED FOR DATE---------------------------------------------
      else {
        /* let lastData = await dataProvider.getStatusList(
          "devices",
          this.uuid,
          1,
          0
        );
        if (lastData.data.length > 0) {
          this.currentAlt = JSON.parse(
            lastData.data[0].records[
              this.searchIndex(lastData.data[0]).gpsIndex
            ].data
          ).altitude;
          this.currentLat = JSON.parse(
            lastData.data[0].records[
              this.searchIndex(lastData.data[0]).gpsIndex
            ].data
          ).latitude;
          this.currentLon = JSON.parse(
            lastData.data[0].records[
              this.searchIndex(lastData.data[0]).gpsIndex
            ].data
          ).longitude;
          this.currentBattery = JSON.parse(
            lastData.data[0].records[
              this.searchIndex(lastData.data[0]).batteryIndex
            ].data
          ).level.value;
          this.currentSignal = JSON.parse(
            lastData.data[0].records[
              this.searchIndex(lastData.data[0]).networkIndex
            ].data
          ).signal_strength.value;
        }
 */
        /*  dataProvider.getStatusListCount("devices", params).then((res) => {
          this.pageCount = Math.ceil(res.data / this.pageSize);
        }); */
        /* console.log(this.uuid,
          this.pageSize,
          this.page) */
        let jurnal = await dataProvider.getJurnalList(
          "devices",
          this.uuid,
          this.pageSize,
          this.page
        );
        //console.log(jurnal)
        this.num = jurnal.data;
        this.alt = [];
        this.lon = [];
        this.lat = [];
        this.time = [];
        this.errorCode = [];
        this.errorData = [];
        this.battery = [];
        this.signal = [];
        jurnal.data.forEach((elem) => {
          let indexes = this.searchIndex(elem);
          if (indexes.lat != undefined) {
            this.alt.push(String(JSON.parse(elem.records[indexes.alt].data)));
            this.lon.push(String(JSON.parse(elem.records[indexes.lon].data)));
            this.lat.push(String(JSON.parse(elem.records[indexes.lat].data)));
          } else {
            this.alt.push("");
            this.lon.push("");
            this.lat.push("");
          }
          if (elem.timestamp != undefined) {
            this.time.push(elem.timestamp);
          } else {
            this.time.push("");
          } /* 
          if (indexes.batteryIndex != undefined) {
            this.battery.push(
              JSON.parse(elem.records[indexes.batteryIndex].data).level.value
            );
          } else {
            this.battery.push("");
          }
          if (indexes.networkIndex != undefined) {
            this.signal.push(
              JSON.parse(elem.records[indexes.networkIndex].data)
                .signal_strength.value
            );
            localStorage.setItem("currentSignal", this.signal[0]);
          } else {
            this.signal.push("");
          }

          if (indexes.errorIndex != undefined) {
            this.errorData.push(
              JSON.parse(elem.records[indexes.errorIndex].data).message
            );
            this.errorCode.push(
              JSON.parse(elem.records[indexes.errorIndex].data).code
            );
          } else {
            this.errorData.push("");
            this.errorCode.push("");
          } */
        });
        document.getElementById("spinner").style.display = "none";

        //--------------------------------MARKERS-------------------------------------------
        /* this.markers.forEach((element, index) => {
          this.mymap.removeLayer(this.markers[index]);
        });
        this.markers = [];
        this.lat.forEach((element, index) => {
          if (
            document.getElementById("flexCheckDefault" + index).checked == true
          ) {
            this.markers[index] = L.marker([
              this.lat[index],
              this.lon[index],
            ]).bindPopup(
              "latitude: " +
                this.lat[index] +
                "\n longitude: " +
                this.lon[index] +
                "\n time: " +
                this.time[index]
            );

            this.mymap.addLayer(this.markers[(element, index)]);
          }
        });

        this.colorChange();
        if (this.follow) {
          this.mymap.panTo([this.lat[0], this.lon[0]]);
        } */
      }

      /* return new Promise((resolve) => {
        resolve("resolved");
      }); */
    },
    showHidden(rowId) {
      if (this.shownArray.includes(rowId)) {
        this.shownArray.forEach((element, index) => {
          this.shownArray.splice(index, 1);
        });
      } else {
        this.shownArray.push(rowId);
      }
    },
  },
  //---------------------------------------------------------MOUNTED----------------------------------------------------------------
  async mounted() {
    document.getElementById("spinner").style.display = "block";
    /* this.mymap = L.map("mapid").setView([47.478888, 19.063348], 14);
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 23,
        minZoom: 1, // prevent the map from zooming out to undefined tiles; otherwise, console error
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
        accessToken: this.accessToken,
      }
    ).addTo(this.mymap);
    this.mymap.invalidateSize();*/

    let params = {
      uuid: localStorage.getItem("deviceShow"),
      id: localStorage.getItem("deviceShow"),
    };
/* 
    await dataProvider
      .getStatusListCount("devices", params, this.fromDate, this.toDate)
      .then((res) => {
        this.pageCount = Math.ceil(res.data / this.pageSize);
      });
 */
     let getOne = await dataProvider.getOne("devices", params);
     console.log(getOne)
    this.name = getOne.data.name;
    this.uuid = getOne.data.uuid;
    this.physicalID = getOne.data.physicalId;

    /* let lastData = await dataProvider.getStatusList("devices", this.uuid, 1, 0);
    if (lastData.data.length > 0) {
      this.currentAlt = JSON.parse(
        lastData.data[0].records[this.searchIndex(lastData.data[0]).gpsIndex]
          .data
      ).altitude;
      this.currentLat = JSON.parse(
        lastData.data[0].records[this.searchIndex(lastData.data[0]).gpsIndex]
          .data
      ).latitude;
      this.currentLon = JSON.parse(
        lastData.data[0].records[this.searchIndex(lastData.data[0]).gpsIndex]
          .data
      ).longitude;

      this.currentBattery = JSON.parse(
        lastData.data[0].records[
          this.searchIndex(lastData.data[0]).batteryIndex
        ].data
      ).level.value;
      this.currentSignal = JSON.parse(
        lastData.data[0].records[
          this.searchIndex(lastData.data[0]).networkIndex
        ].data
      ).signal_strength.value;
    } */

    let jurnalList = await dataProvider.getJurnalList(
      "devices",
      this.uuid,
      this.pageSize,
      this.page
    );
    //console.log(jurnalList)
    document.getElementById("spinner").style.display = "none";
    this.num = jurnalList.data;
    console.log(this.num);
    console.log(JSON.parse(JSON.parse(this.num[0].records[0].data)));

    //jurnalList.data.forEach((elem) => {
    /* console.log(elem)
      let indexes = this.searchIndex(elem);
      if (indexes.lat != undefined) {
        this.alt.push(String(JSON.parse(elem.records[indexes.alt].data)));
        this.lon.push(String(JSON.parse(elem.records[indexes.lon].data)));
        this.lat.push(String(JSON.parse(elem.records[indexes.lat].data)));
      } else {
        this.alt.push("");
        this.lon.push("");
        this.lat.push("");
      }
      if (elem.timestamp != undefined) {
        this.time.push(elem.timestamp);
      } else {
        this.time.push("");
      } */

    /* 
      if (indexes.batteryIndex != undefined) {
        this.battery.push(
          JSON.parse(elem.records[indexes.batteryIndex].data).level.value
        );
      } else {
        this.battery.push("");
      }
      if (indexes.networkIndex != undefined) {
        this.signal.push(
          JSON.parse(elem.records[indexes.networkIndex].data).signal_strength
            .value
        );
        localStorage.setItem("currentSignal", this.signal[0]);
      } else {
        this.signal.push("");
      }

      if (indexes.errorIndex != undefined) {
        this.errorData.push(
          JSON.parse(elem.records[indexes.errorIndex].data).message
        );
        this.errorCode.push(
          JSON.parse(elem.records[indexes.errorIndex].data).code
        );
      } else {
        this.errorData.push("");
        this.errorCode.push("");
      } */
    //});

    //---------------------------------------MARKER-----------------------------------------------
    /* this.lat.forEach((element, index) => {
      this.markers.push(
        L.marker([this.lat[index], this.lon[index]]).bindPopup(
          "latitude: " +
            this.lat[index] +
            "\n longitude: " +
            this.lon[index] +
            "\n time: " +
            this.time[index]
        )
      );
      this.mymap.addLayer(this.markers[index]);
    });

    this.colorChange();
    if (this.follow) {
      this.mymap.panTo([this.lat[0], this.lon[0]]);
    } */
  },
  //------------------------------------------------------------UNMOUNT----------------------------------------
  beforeUnmount() {
    this.pushed = 0;
    this.follow == false;
  },
  /////-----------------------------------------------------------ON UPDATED------------------------------------------------
  updated() {
    /*if (this.filterValue) {
      this.setDateFilter();
    }*/
    if (this.errorValue) {
      this.setErrorFilter();
    } /* else{
      this.setErrorFilter()
    } */
  },
  //-------------------------------------------------------------------DATA-----------------------------------------------------
  data() {
    return {
      ///---dropdown---
      shownArray: [],
      ///Table List
      dataNum: 20,
      page: 0,
      pageNum: 1,
      pageSize: 20,
      time: [],
      lat: [],
      lon: [],
      alt: [],
      battery: [],
      errorData: [],
      errorCode: [],
      satelite: null,
      signal: [],
      ///Last Coords
      currentAlt: null,
      currentLat: null,
      currentLon: null,
      currentTime: null,
      currentBattery: null,
      currentSignal: null,
      timeZoneValue: 0,

      pageCount: 0,

      errorFilter: null,
      errorMessage: "",
      latlngs: [],
      //routeValue: false,
      //actionsHide: true,

      refreshSlide: 0,
      followSlide: 0,
      filterSlide: 0,
      errorSlide: 0,
      routeSlide: 0,

      batteryValue: 0,
      signalValue: 0,

      errorValue: false,
      filterValue: false,

      user: localStorage.getItem("username"),

      timeValue: 0,
      follow: false,
      refresh: false,
      toDate: new Date().toISOString().slice(0, 10),

      timeZones: [
        ["UTC-12:00", -12],
        ["UTC-11:00", -11],
        ["UTC-10:00", -10],
        ["UTC-09:00", -9],
        ["UTC-08:00", -8],
        ["UTC-07:00", -7],
        ["UTC-06:00", -6],
        ["UTC-05:00", -5],
        ["UTC-04:00", -4],
        ["UTC-03:00", -3],
        ["UTC-02:00", -2],
        ["UTC-01:00", -1],
        ["UTC-00:00", +0],
        ["UTC+01:00", +1],
        ["UTC+02:00", +2],
        ["UTC+03:00", +3],
        ["UTC+04:00", +4],
        ["UTC+05:00", +5],
        ["UTC+06:00", +6],
        ["UTC+07:00", +7],
        ["UTC+08:00", +8],
        ["UTC+09:00", +9],
        //["UTC+09:30",+9.5],
        ["UTC+10:00", +10],
        //["UTC+10:30",+10.5],
        ["UTC+11:00", +11],
        ["UTC+12:00", +12],
        // ["UTC+12:45",+12.75],
        ["UTC+13:00", +13],
        ["UTC+14:00", +14],
      ],

      ///details
      name: null,
      uuid: null,
      physicalID: null,
      error: null,
      num: null,
      pushed: 0,
      date: "",

      ////MAP
      accessToken:
        "pk.eyJ1IjoibHVkbnlhayIsImEiOiJja3VwOHVkMDAyaWdzMnduNmF3M2pob2lmIn0.LgLNI7D8uEJPRXi18Ul7vQ",
      mymap: null,
      markers: [],
      markersFirst: [],
      greenIcon: new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      }),
      blueIcon: new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      }),
      defaultIcon: null,
    };
  },
};
</script>
<style lang="scss" scoped>
/*------------------------------------------ CSS -----------------------------------------*/

/*----------------------- Navbar ---------------------*/

#hiddenTitle {
  font-size: 20px;
}

.spinner-container {
  position: absolute;
  top: 50vh;
  left: 50vw;
  display: none;
}

#appbar {
  min-height: 50px;
  max-height: 60px;
}
/*----------- Home -------------*/
#navButtonHomeDeviceShow {
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
  min-width: 120px;
  width: 8vw;
  max-width: 200px;
  height: 35px;
}

#navButtonHomeDeviceShow:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonHomeDeviceShow:hover {
  background-color: #42ba85;
  color: black;
}

/*----------- Domains -------------*/

#navButtonDomainsDeviceShow {
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
  min-width: 120px;
  width: 8vw;
  max-width: 200px;
  height: 35px;
}

#navButtonDomainsDeviceShow:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonDomainsDeviceShow:hover {
  background-color: #42ba85;
  color: black;
}

/*----------- Devices -------------*/
#navButtonActiveDeviceShow {
  background-color: #42ba85;
  color: black;
  border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
  min-width: 120px;
  width: 8vw;
  max-width: 200px;
  height: 35px;
}

#navButtonDomainsDeviceShow:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonDomainsDeviceShow:hover {
  background-color: #42ba85;
  color: black;
}

/*----------- Users -------------*/
#navButtonUsersDeviceShow {
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
  min-width: 120px;
  width: 8vw;
  max-width: 200px;
  height: 35px;
}

#navButtonUsersDeviceShow:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonUsersDeviceShow:hover {
  background-color: #42ba85;
  color: black;
}

/*----------- User, logout -------------*/
#logoutButtonDeviceShow {
  position: fixed;
  float: right;
  right: 30px;
  background-color: #20262e;
  display: flex;
  top: 0.1vh;
  width: 7%;
  border: 0;
  cursor: pointer;
  min-width: 150px;
  max-width: 200px;
}

#logoutButtonDeviceShow:hover {
  color: #42ba85;
}

#logoutButtonDeviceShow:focus {
  outline: none;
  box-shadow: none;
}

/*----------- Device name -------------*/
.topCard {
  background-color: #20262e;
  position: relative;
  height: 200px;
  margin: auto;
  width: 93vw;
}
.deviceHeaderPlace b {
  color: #42ba85;
}
.deviceHeader {
  font-size: 20px;
  color: white;
  position: relative;
  left: 0px;
}

#headerContainer {
  display: flex;
  position: relative;
}

/*----------- Export Data Button -------------*/
#exportButtonId {
  color: #42ba85;
  background-color: #20262e;
  border: 0;
  position: absolute;
  border: 0.1vw solid #42ba85;
  min-width: 120px;
  max-width: 300px;
  min-height: 35px;
  max-height: 70px;
  width: 160px;
  left: 0.7vw;
  margin-top: 20px;
}

#exportButtonId:hover {
  color: #20262e;
  background-color: #42ba85;
}

/*----------- Last GPS Coordinates -------------*/

#lastCoords {
  color: white;
  font-size: 20px;
  position: absolute;
  left: 40vw;
  top: 0px;
  margin: 0vw;
  width: 60%;
  min-width: 75%;
}

#lastCoords b {
  color: #42ba85;
}
/*----------- Battery and signal -------------*/
#deviceInfo {
  color: white;
  position: absolute;
  left: 65vw;
  width: 25%;
  height: 0px;
  top: 0px;
  margin: 0vw;
}

.loading-bar {
  position: relative;
  width: 100%;
  height: 28px;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba($color: #000, $alpha: 0.4), 0 -1px 1px #fff,
    0 1px 0 #fff;

  .percentage {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    display: block;
    height: 100%;
    border-radius: 15px;
    background-size: 2vw 2vw;
    background-image: linear-gradient(
      135deg,
      rgba($color: #fff, $alpha: 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba($color: #fff, $alpha: 0.15) 50%,
      rgba($color: #fff, $alpha: 0.15) 75%,
      transparent 75%,
      transparent
    );
    animation: animate-stripes 3s linear infinite;
  }
}
.networkPercentage {
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  display: block;
  height: 100%;
  border-radius: 15px;
  background-size: 2vw 2vw;
  background-image: linear-gradient(
    135deg,
    rgba($color: #fff, $alpha: 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba($color: #fff, $alpha: 0.15) 50%,
    rgba($color: #fff, $alpha: 0.15) 75%,
    transparent 75%,
    transparent
  );
  animation: animate-stripes 3s linear infinite;
}

#percentageTextBattery {
  position: relative;
  left: 0%;
  top: 0.3vh;
  color: black;
  color: white;
  margin-left: 10vw;
  margin-right: 10vw;
  z-index: 1;
}

#percentageTextSignal {
  position: relative;
  left: 0%;
  top: 0.2vh;
  color: black;
  color: white;
  z-index: 1;
}

/*----------- DeviceShow -------------*/
.deviceShow {
  position: relative;
  top: 80px;
  overflow-x: hidden;
}

.deviceShowBackGround {
  height: 100vh;
  width: 100vw;
  background-color: #2a323d;
  overflow-x: hidden;
  overflow-y: scroll;
}

/*----------- Map and table -------------*/
.table-and-map {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  position: relative;
  left: 0;
  background-color: #20262e;
  color: white;
}

/*----------- OuterActionBar -------------*/
.outerActionBar {
  background-color: #20262e;
  position: relative;
  width: 93vw;
  min-height: 150px;
  color: white;
  margin: auto;
}
/*----------- Timezone button -------------*/
#timeZoneId {
  position: absolute;
  overflow: visible;
  left: 10px;
  top: 15px;
  width: 160px;
  min-width: 140px;
  min-height: 40px;
  max-width: 160px;
  max-height: 60px;
  background-color: #42ba85;
}
.dropdown-menu {
  max-height: 250px;
  overflow-y: scroll;
}

/*----------- Row number button -------------*/
#pageSizeButton {
  position: absolute;
  top: 80px;
  left: -5px;
  width: 160px;
  min-width: 140px;
  min-height: 40px;
  max-width: 160px;
  max-height: 60px;
  background-color: #42ba85;
}

/*----------- Refresh label and switch -------------*/
#refreshLabel {
  top: 30px;
  left: 200px;
  position: absolute;
}
.refreshSwitch {
  position: absolute;
  display: inline-block;
  top: 25px;
  left: 280px;
  width: 4vw;
  min-width: 60px;
  margin-right: 0.5vw;
  height: 5vh;
  min-height: 35px;
  max-width: 5px;
  max-height: 35px;
}
#refreshCheck {
  margin-left: 20px;
}

/*----------- Follow label and switch -------------*/
.followLabel {
  top: 95px;
  left: 200px;
  position: absolute;
}
.followSwitch {
  position: absolute;
  display: inline-block;
  left: 280px;
  top: 90px;
  width: 4vw;
  min-width: 60px;
  margin-right: 0.5vw;
  height: 5vh;
  min-height: 35px;
  max-width: 5px;
  max-height: 35px;
}
#followCheck {
  margin-left: 20px;
}

/*----------- Errors label and switch -------------*/
.errorsLabel {
  top: 30px;
  left: 400px;
  position: absolute;
}
.errorsSwitch {
  position: absolute;
  display: inline-block;
  top: 25px;
  left: 480px;
  width: 4vw;
  min-width: 60px;
  margin-right: 0.5vw;
  height: 5vh;
  min-height: 35px;
  max-width: 5px;
  max-height: 35px;
}
#errorsCheck {
  margin-left: 20px;
}

/*----------- Filter label and switch -------------*/
.filterLabel {
  top: 95px;
  left: 400px;
  position: absolute;
}
.filterSwitch {
  position: absolute;
  display: inline-block;
  top: 90px;
  left: 480px;
  width: 4vw;
  min-width: 60px;
  margin-right: 0.5vw;
  height: 5vh;
  min-height: 35px;
  max-width: 5px;
  max-height: 35px;
}
#filterCheck {
  margin-left: 20px;
}

/*----------- Error switch active -------------*/
.errorInputLabel {
  display: none;
}
#errorInput {
  top: 25px;
  color: white;
  max-width: 120px;
  min-width: 120px;
  left: 600px;
  background-color: #20262e;
  position: absolute;
  min-height: 40px;
}
.errorButton {
  background-color: #42ba85;
  border: 0;
  color: white;
  min-height: 40px;
  top: 25px;
  min-width: 40px;
  left: 700px;
  border-radius: 0vw 0.3vw 0.3vw 0vw;
  position: absolute;
}

/*----------- Filter switch active -------------*/
#startText {
  left: 740px;
  top: 30px;
  position: absolute;
}

#endText {
  left: 740px;
  top: 95px;
  position: absolute;
}
#startDate {
  border: 0.1vw solid white;
  border-radius: 5px;
  min-height: 40px;
  top: 25px;
  left: 860px;
  min-width: 140px;
  width: 10vw;
  background-color: #20262e;
  color: grey;
  position: absolute;
  max-width: 150px;
}

#endDate {
  border: 0.1vw solid white;
  border-radius: 5px;
  min-height: 40px;
  top: 90px;
  left: 860px;
  min-width: 140px;
  width: 10vw;
  max-width: 150px;
  background-color: #20262e;
  color: grey;
  position: absolute;
}

.dateButton {
  background-color: #42ba85;
  border: 0;
  color: white;
  height: 40px;
  top: 25px;
  left: 1050px;
  min-width: 80px;
  border-radius: 0.3vw;
  position: absolute;
}

#dateStuff1 {
  display: flex;
}

#dateStuff2 {
  display: flex;
}

/*----------- Info button -------------*/
#infoButton {
  position: absolute;

  color: white;
  top: -20px;
  background-color: none;
  left: 90vw;
}
#infoDropdown {
  width: 500px;
  color: white;
}
#infoDropdown p {
  color: white;
  font-size: 15px;
  margin-top: 5px;
  padding: 0px;
  margin-left: 5px;
}
#infoDropdown p b {
  color: mediumseagreen;
  font-weight: bold;
  margin-left: 5px;
  margin-top: 5px;
  font-size: 15px;
  padding: 0px;
}

/*----------- Table and Map-------------*/
.coordTableRow {
  cursor: pointer;
  width: 50vw;
}

.pagination {
  margin-left: 0vw;
  right: 0vw;
  position: relative;
  margin-bottom: 5vh;
}
.topTableRow {
  top: 0px;
  background-color: #20262e;
}

.pagingBarBottom {
  position: relative;
  margin-left: 12vw;
  top: 5vh;
  margin-bottom: 5vh;
}
#pageDownButton {
  cursor: pointer;
}

#pageUpButton {
  cursor: pointer;
}

.flexCheckDefault {
  background-color: #20262e;
  border: 1px solid #42ba85;
  cursor: pointer;
  position: relative;
  bottom: 1px;
}

.flexCheckDefault:checked {
  border: 1px solid #42ba85;
  box-shadow: none;
}

.my-custom-scrollbar {
  position: relative;
  height: 80vh;
  overflow: auto;
  max-height: 800px;
}
.table-wrapper-scroll-y {
  display: block;
}

#downArrowIcon {
  color: white;
}

#tableCardId {
  height: 110vh;
  width: 93vw;
  min-height: 500px;
  max-height: 1150px;
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}

.table {
  position: relative;
  margin-bottom: 0px;
  background-color: #20262e;
  color: white;
}

.tableCard {
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 93vw;
  height: 100vh;
  overflow: hidden;
  color: white;
  background-color: #20262e;
  margin-top: 20px;
  left: -5px;
  margin: auto;
}

.page-link {
  color: #42ba85;
  background-color: #2a323d;
  border: solid 1px #42ba85;
}

.smallMapOpen {
  background-color: #2a323d;
}

.page-link:hover {
  color: black;
  background-color: #42ba85;
}

.map {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  position: relative;
  left: 5px;
  color: white;
  background-color: #20262e;
  margin-top: 20px;
  margin-bottom: 10px;
}
#mapid {
  height: 105vh;
  max-height: 950px;
  width: 41vw;
  z-index: 0;
  margin-top: 20px;
}

#mapContainer {
  width: 42.5vw;
  position: relative;
  left: 5px;
  margin-bottom: 0px;
  margin-top: 20px;
}
.headerText {
  position: relative;
  left: 10px;
}

#pageLink1 {
  cursor: pointer;
}
#pageLink2 {
  cursor: pointer;
}

#smallMap {
  width: 450px;
  background-color: #2a323d;
  color: white;
  position: relative;
  left: -1px;
}

.mapDropdown {
  background: none;
  border: 0;
  position: relative;
  bottom: 35%;
  background-color: #20262e;
  font-size: 20px;
  text-align: center;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2a323d;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2a323d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2a323d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*----------------------------- Logo ---------------------------*/

* {
  box-sizing: border-box;
}
.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 45px;
  margin-top: 2.5px;
  margin-left: 10px;
}
.item {
  align-items: center;
  display: flex;
  height: 7.5px;
  justify-content: center;
  position: relative;
  width: 7.5px;
  z-index: 100;
  img {
    object-fit: cover;
    height: 45px;
    width: 45px;
  }
  &::before,
  &::after {
    background-image: linear-gradient(-180deg, #0076a3 0%, #072b3c 97%);
    content: "";
    height: 7.5px;
    position: absolute;
    transition: all 0.2s;
    transition-delay: 0.2s;
    transform: translateY(0px) translateX(0px);
    width: 7.5px;
    z-index: -1;
  }
  &::after {
    background-image: linear-gradient(0deg, #8c9a27 0%, #d3d821 97%);
  }
  &:hover {
    animation-name: hoverPop;
    animation-duration: 0.4s;
    &::before {
      animation: slick-hover-2 3s 0.4s linear infinite;
      transform: translateY(-20px) translateX(-20px);
    }
    &::after {
      animation: slick-hover 3s 0.4s linear infinite reverse;
      transform: translateY(20px) translateX(20px);
    }
  }
}

@keyframes hoverPop {
  0%,
  100% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.05);
  }
  66% {
    transform: scale(0.9);
  }
}

@keyframes slick-hover {
  0.00% {
    transform: translateY(20px) translateX(20px);
  }
  16.67% {
    transform: translateY(-13px) translateX(-9px);
  }
  33.33% {
    transform: translateY(4px) translateX(12px);
  }
  50.00% {
    transform: translateY(20px) translateX(-20px);
  }
  66.67% {
    transform: translateY(-3px) translateX(8px);
  }
  83.33% {
    transform: translateY(-15px) translateX(-11px);
  }
  100.00% {
    transform: translateY(20px) translateX(20px);
  }
}

@keyframes slick-hover-2 {
  0.00% {
    transform: translateY(-20px) translateX(-20px);
  }
  16.67% {
    transform: translateY(3px) translateX(-5px);
  }
  33.33% {
    transform: translateY(18px) translateX(-10px);
  }
  50.00% {
    transform: translateY(-20px) translateX(20px);
  }
  66.67% {
    transform: translateY(15px) translateX(-4px);
  }
  83.33% {
    transform: translateY(-1px) translateX(15px);
  }
  100.00% {
    transform: translateY(-20px) translateX(-20px);
  }
}

/*----------------------------- H ---------------------------*/

/* h7 {
  font-size: 25px;
}
h8 {
  color: #42ba85;
  font-size: 25px;
  font-weight: bold;
} */

/*----------------------------- Media ---------------------------*/

@media (max-width: 1260px) and (min-width: 1010px) {
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

  #logoutButtonDeviceShow {
    right: 0px;
  }
  /*----------- Device name -------------*/
  .topCard {
    left: 0px;
    min-width: 990px;
  }

  .deviceHeaderPlace {
    margin-left: 0px;
  }

  #exportButtonId {
    margin-left: 0px;
  }
  /*----------- Last GPS Coordinates -------------*/

  #lastCoords {
    position: relative;
    left: 45vw;
    top: -155px;
  }

  /*----------- Battery and signal -------------*/

  #deviceInfo {
    left: 70vw;
    top: 0px;
    width: 250px;
  }

  /*----------- OuterActionBar -------------*/
  .outerActionBar {
    top: 0px;
    min-height: 250px;
    left: 0px;
    width: 93vw;
    min-width: 990px;
  }

  /*----------- Timezone button -------------*/
  #timeZoneId {
    left: 10px;
  }
  /*----------- Row number button -------------*/
  #pageSizeButton {
    left: -5px;
  }

  /*----------- Refresh label and switch -------------*/
  #refreshLabel {
    top: 40px;
  }
  .refreshSwitch {
    top: 30px;
  }

  /*----------- Follow label and switch -------------*/
  .followLabel {
    top: 100px;
  }
  .followSwitch {
    top: 95px;
  }

  /*----------- Errors label and switch -------------*/
  .errorsLabel {
    top: 35px;
    left: 450px;
  }
  .errorsSwitch {
    top: 30px;
    left: 520px;
  }
  /*----------- Error switch active -------------*/
  .errorInputLabel {
    position: absolute;
    left: 720px;
    top: 35px;
  }

  #errorInput {
    top: 30px;
    left: 790px;
    position: absolute;
  }
  .errorButton {
    top: 30px;
    left: 890px;
    position: absolute;
  }

  /*----------- Filter label and switch -------------*/
  .filterLabel {
    top: 100px;
    left: 450px;
  }
  .filterSwitch {
    top: 95px;
    left: 520px;
  }
  /*----------- Filter switch active -------------*/
  #startText {
    left: 670px;
    top: 100px;
    position: absolute;
  }

  #endText {
    left: 670px;
    top: 160px;
    position: absolute;
  }
  #startDate {
    top: 95px;
    left: 790px;
    position: absolute;
  }

  #endDate {
    top: 150px;
    left: 790px;
    position: absolute;
  }

  .dateButton {
    top: 200px;
    left: 790px;
    position: absolute;
  }

  /*----------- Info button -------------*/
  #infoButton {
    left: 91vw;
    top: -25px;
  }

  /*----------- Table and Map-------------*/
  .tableCard {
    margin-top: 0px;
    position: relative;
    min-width: 470px;
  }
  #mapContainer {
    position: relative;
    left: 10px;
    margin-bottom: 0px;
    margin-top: 0px;
    min-width: 420px;
    width: 40vw;
  }
  #tableCardId {
    height: 110vh;
    width: 52vw;
    left: -5px;
    min-height: 500px;
  }

  #mapid {
    right: 3px;
    min-width: 400px;
    width: 38vw;
  }

  .pagination {
    margin-left: 0px;
    left: 0px;
    position: relative;
    margin-bottom: 10px;
  }
  .pagingBarBottom {
    position: relative;
    margin-left: 100px;
    top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1010px) and (min-width: 750px) {
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

  #logoutButtonDeviceShow {
    right: 0px;
  }

  /*----------- Home -------------*/
  #navButtonHomeDeviceShow {
    font-size: 14px;
    min-width: 85px;
    left: 10px;
  }

  /*----------- Domains -------------*/

  #navButtonDomainsDeviceShow {
    font-size: 14px;
    min-width: 105px;
    left: 10px;
  }

  /*----------- Devices -------------*/
  #navButtonActiveDeviceShow {
    font-size: 14px;
    min-width: 95px;
    left: 10px;
  }

  /*----------- Users -------------*/
  #navButtonUsersDeviceShow {
    font-size: 14px;
    min-width: 85px;
    left: 10px;
  }

  /*----------- User, logout -------------*/
  #logoutButtonDeviceShow {
    font-size: 14px;
    margin-right: 20px;
    min-width: 10px;
  }

  /*----------- Device name -------------*/
  .topCard {
    left: 0px;

    max-width: 80vw;
    height: 550px;
  }

  .deviceHeaderPlace {
    min-width: 45vw;
    margin-left: 14.5vw;
  }

  #exportButtonId {
    margin-left: 15vw;
    margin-top: -20px;
  }
  /*----------- Last GPS Coordinates -------------*/

  #lastCoords {
    margin-left: -24.5vw;
    min-width: 60vw;
    top: 180px;
  }

  /*----------- Battery and signal -------------*/

  #deviceInfo {
    margin-left: -50vw;
    min-width: 60%;
    height: 0px;
    top: 350px;
  }

  /*----------- OuterActionBar -------------*/
  .outerActionBar {
    top: 0px;
    min-height: 200px;
    left: 0px;
    max-width: 80vw;
  }
  /*----------- Timezone button -------------*/
  #timeZoneId {
    left: 20px;
    top: 5px;
    max-width: 130px;
    width: 130px;
    min-width: 100px;
  }
  /*----------- Row number button -------------*/
  #pageSizeButton {
    left: 5px;
    top: 60px;
    max-width: 130px;
    width: 130px;
    min-width: 100px;
  }

  /*----------- Refresh label and switch -------------*/
  #refreshLabel {
    top: 20px;
    left: 20vw;
  }
  .refreshSwitch {
    top: 15px;
    left: 30vw;
  }

  /*----------- Follow label and switch -------------*/
  .followLabel {
    top: 75px;
    left: 20vw;
  }
  .followSwitch {
    top: 70px;
    left: 30vw;
  }

  /*----------- Errors label and switch -------------*/
  .errorsLabel {
    top: 20px;
    left: 43vw;
    position: absolute;
  }
  .errorsSwitch {
    position: absolute;
    top: 15px;
    left: 53vw;
  }

  /*----------- Filter label and switch -------------*/
  .filterLabel {
    top: 75px;
    left: 43vw;
    position: absolute;
  }
  .filterSwitch {
    position: absolute;
    top: 70px;
    left: 53vw;
  }

  /*----------- Error switch active -------------*/
  #errorInput {
    top: 130px;
    left: 20px;
    position: absolute;
    min-width: 120px;
    max-width: 120px;
  }
  .errorButton {
    top: 130px;
    left: 130px;
    position: absolute;
  }

  /*----------- Filter switch active -------------*/
  #startText {
    left: 20px;
    top: 140px;
    position: absolute;
  }

  #endText {
    left: 270px;
    top: 140px;
    position: absolute;
  }
  #startDate {
    top: 130px;
    left: 140px;
    position: absolute;
    min-width: 110px;
    width: 50px;
  }

  #endDate {
    top: 130px;
    left: 390px;
    position: absolute;
    min-width: 110px;
    width: 50px;
  }

  .dateButton {
    top: 130px;
    left: 510px;
    position: absolute;
  }

  /*----------- Info button -------------*/
  #infoButton {
    position: absolute;
    left: 77vw;
    top: -30px;
  }

  #infoDropdown {
    width: 300px;
  }

  /*----------- Table and Map-------------*/
  .table {
    width: 100%;
    height: 50vh;
    left: 0px;
  }

  .tableCard {
    margin-top: 0px;
    position: relative;
    left: 0px;
    width: 80vw;
  }
  #mapContainer {
    position: relative;
    left: 0px;
    margin-bottom: 0px;
    margin-top: 50px;
    min-width: 400px;
    width: 80vw;
  }
  #tableCardId {
    min-width: 400px;
    height: 110vh;
    left: 0px;
    width: 80vw;
    min-height: 500px;
  }

  #mapid {
    right: 3px;
    min-width: 375px;
    width: 77vw;
    margin-bottom: 20px;
  }

  #smallMap {
    width: 330px;
  }
  .pagination {
    position: relative;
    margin-bottom: 10px;
    margin-right: 0vw;
    max-width: 10px;
  }
  .pagingBarBottom {
    position: relative;
    top: 20px;
    margin-bottom: 20px;
    left: 5vw;
    max-width: 10px;
  }
  /* .page-item{
    font-size:12px;
    max-width:70px;
  } */
}
@media (max-width: 750px) and (min-width: 600px) {
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }
  /*----------- Home -------------*/
  #navButtonHomeDeviceShow {
    font-size: 14px;
    min-width: 85px;
    left: 10px;
  }

  /*----------- Domains -------------*/

  #navButtonDomainsDeviceShow {
    font-size: 14px;
    min-width: 105px;
    left: 10px;
  }

  /*----------- Devices -------------*/
  #navButtonActiveDeviceShow {
    font-size: 14px;
    min-width: 95px;
    left: 10px;
  }

  /*----------- Users -------------*/
  #navButtonUsersDeviceShow {
    font-size: 14px;
    min-width: 85px;
    left: 10px;
  }

  /*----------- User, logout -------------*/
  #logoutButtonDeviceShow {
    font-size: 14px;
    margin-right: 20px;
    min-width: 10px;
    right: 0px;
  }

  /*----------- Device name -------------*/
  .topCard {
    left: 0px;
    max-width: 80vw;
    height: 550px;
  }

  .deviceHeaderPlace {
    min-width: 45vw;
    margin-left: 14.5vw;
  }

  #exportButtonId {
    margin-left: 15vw;
    margin-top: -20px;
  }
  /*----------- Last GPS Coordinates -------------*/

  #lastCoords {
    margin-left: -24.5vw;
    min-width: 60vw;
    top: 180px;
  }

  /*----------- Battery and signal -------------*/

  #deviceInfo {
    margin-left: -50vw;
    min-width: 60%;
    height: 0px;
    top: 350px;
  }

  /*----------- OuterActionBar -------------*/
  .outerActionBar {
    top: 0px;
    min-height: 350px;
    left: 0px;
    max-width: 80vw;
  }
  /*----------- Timezone button -------------*/
  #timeZoneId {
    left: 120px;
    top: 25px;
    max-width: 130px;
    width: 130px;
    min-width: 100px;
  }
  /*----------- Row number button -------------*/
  #pageSizeButton {
    left: 105px;
    top: 85px;
    max-width: 130px;
    width: 130px;
    min-width: 100px;
  }

  /*----------- Refresh label and switch -------------*/
  #refreshLabel {
    top: 40px;
    left: 290px;
  }
  .refreshSwitch {
    top: 35px;
    left: 370px;
  }

  /*----------- Follow label and switch -------------*/
  .followLabel {
    top: 100px;
    left: 290px;
  }
  .followSwitch {
    top: 95px;
    left: 370px;
  }

  /*----------- Errors label and switch -------------*/
  .errorsLabel {
    top: 165px;
    left: 120px;
    position: absolute;
  }
  .errorsSwitch {
    position: absolute;
    top: 160px;
    left: 180px;
  }

  /*----------- Filter label and switch -------------*/
  .filterLabel {
    top: 235px;
    left: 120px;
    position: absolute;
  }
  .filterSwitch {
    position: absolute;
    top: 230px;
    left: 180px;
  }

  /*----------- Error switch active -------------*/
  #errorInput {
    top: 160px;
    left: 290px;
    position: absolute;
  }
  .errorButton {
    top: 160px;
    left: 390px;
    position: absolute;
  }

  /*----------- Filter switch active -------------*/
  #startText {
    left: 290px;
    top: 212px;
    position: absolute;
  }

  #endText {
    left: 290px;
    top: 258px;
    position: absolute;
  }
  #startDate {
    top: 205px;
    left: 410px;
    position: absolute;
    min-width: 100px;
    width: 5vw;
  }

  #endDate {
    top: 250px;
    left: 410px;
    position: absolute;
    min-width: 100px;
    width: 5vw;
  }

  .dateButton {
    top: 300px;
    left: 350px;
    position: absolute;
  }

  /*----------- Info button -------------*/
  #infoButton {
    position: absolute;
    left: 76vw;
    top: -30px;
  }

  #infoDropdown {
    width: 300px;
  }

  /*----------- Table and Map-------------*/
  .table {
    width: 120%;
    height: 60vh;
    left: 0px;
  }

  .tableCard {
    margin-top: 0px;
    position: relative;
    left: 0px;
    width: 80vw;
  }
  #mapContainer {
    position: relative;
    left: 0px;
    margin-bottom: 0px;
    margin-top: 50px;
    min-width: 400px;
    width: 80vw;
  }
  #tableCardId {
    min-width: 400px;
    height: 110vh;
    left: 0px;
    width: 80vw;
    min-height: 500px;
  }

  #mapid {
    right: 3px;
    min-width: 375px;
    width: 77vw;
    margin-bottom: 20px;
  }

  #smallMap {
    width: 330px;
  }
  .pagination {
    position: relative;
    margin-bottom: 10px;
    margin-right: 0vw;
    max-width: 10px;
  }
  .pagingBarBottom {
    position: relative;
    top: 20px;
    margin-bottom: 20px;
    left: 0vw;
    max-width: 10px;
  }
  .page-item {
    font-size: 12px;
    max-width: 70px;
  }
}

@media (max-width: 600px) and (min-width: 501px) {
  #hiddenTitle {
    display: none;
  }

  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

  #logoutButtonDeviceShow {
    right: 0px;
  }

  /*----------- Home -------------*/
  #navButtonHomeDeviceShow {
    font-size: 14px;
    min-width: 85px;
    left: 10px;
  }

  /*----------- Domains -------------*/

  #navButtonDomainsDeviceShow {
    font-size: 14px;
    min-width: 105px;
    left: 10px;
  }

  /*----------- Devices -------------*/
  #navButtonActiveDeviceShow {
    font-size: 14px;
    min-width: 95px;
    left: 10px;
  }

  /*----------- Users -------------*/
  #navButtonUsersDeviceShow {
    font-size: 14px;
    min-width: 85px;
    left: 10px;
  }

  /*----------- User, logout -------------*/
  #logoutButtonDeviceShow {
    font-size: 14px;
    min-width: 85px;
  }

  /*----------- Device name -------------*/
  .topCard {
    left: 0px;
    max-width: 80vw;
    height: 550px;
  }

  .deviceHeaderPlace {
    max-width: 60vw;
    margin-left: 14.5vw;
  }

  #exportButtonId {
    margin-left: 15vw;
    margin-top: -20px;
  }
  /*----------- Last GPS Coordinates -------------*/

  #lastCoords {
    margin-left: -24.5vw;
    min-width: 60vw;
    top: 200px;
  }

  /*----------- Battery and signal -------------*/

  #deviceInfo {
    margin-left: -50vw;
    min-width: 60%;
    height: 0px;
    top: 350px;
  }

  /*----------- OuterActionBar -------------*/
  .outerActionBar {
    top: 0px;
    min-height: 350px;
    left: 0px;
    max-width: 80vw;
  }
  /*----------- Timezone button -------------*/
  #timeZoneId {
    left: 60px;
    top: 25px;
    max-width: 130px;
    width: 130px;
    min-width: 100px;
  }
  /*----------- Row number button -------------*/
  #pageSizeButton {
    left: 45px;
    top: 85px;
    max-width: 130px;
    width: 130px;
    min-width: 100px;
  }
  /*----------- Refresh label and switch -------------*/
  #refreshLabel {
    top: 40px;
    left: 220px;
  }
  .refreshSwitch {
    top: 35px;
    left: 310px;
  }

  /*----------- Follow label and switch -------------*/
  .followLabel {
    top: 100px;
    left: 220px;
  }
  .followSwitch {
    top: 95px;
    left: 310px;
  }
  /*----------- Errors label and switch -------------*/
  .errorsLabel {
    top: 165px;
    left: 60px;
    position: absolute;
  }
  .errorsSwitch {
    position: absolute;
    top: 160px;
    left: 120px;
  }

  /*----------- Filter label and switch -------------*/
  .filterLabel {
    top: 235px;
    left: 60px;
    position: absolute;
  }
  .filterSwitch {
    position: absolute;
    top: 230px;
    left: 120px;
  }

  /*----------- Error switch active -------------*/
  #errorInput {
    top: 160px;
    left: 220px;
    position: absolute;
  }
  .errorButton {
    top: 160px;
    left: 330px;
    position: absolute;
  }

  /*----------- Filter switch active -------------*/
  #startText {
    left: 220px;
    top: 212px;
    position: absolute;
  }

  #endText {
    left: 220px;
    top: 258px;
    position: absolute;
  }
  #startDate {
    top: 205px;
    left: 340px;
    position: absolute;
    min-width: 100px;
    width: 5vw;
  }

  #endDate {
    top: 250px;
    left: 340px;
    position: absolute;
    min-width: 100px;
    width: 5vw;
  }

  .dateButton {
    top: 300px;
    left: 290px;
    position: absolute;
  }

  /*----------- Info button -------------*/
  #infoButton {
    position: absolute;
    left: 75vw;
    top: -30px;
  }

  #infoDropdown {
    width: 300px;
  }

  /*----------- Table and Map-------------*/
  .table {
    width: 130%;
    height: 60vh;
    left: 0px;
  }

  .tableCard {
    margin-top: 0px;
    position: relative;
    left: 0px;
    width: 80vw;
  }
  #mapContainer {
    position: relative;
    left: 0px;
    margin-bottom: 0px;
    margin-top: 50px;
    min-width: 400px;
    width: 80vw;
  }
  #tableCardId {
    min-width: 400px;
    height: 110vh;
    left: 0px;
    width: 80vw;
    min-height: 500px;
  }

  #mapid {
    right: 3px;
    min-width: 375px;
    width: 77vw;
    margin-bottom: 20px;
  }

  #smallMap {
    width: 330px;
  }
  .pagination {
    position: relative;
    margin-bottom: 10px;
    margin-right: 0vw;
    max-width: 10px;
  }
  .pagingBarBottom {
    position: relative;
    top: 20px;
    margin-bottom: 20px;
    left: -7vw;
    max-width: 10px;
  }
  .page-item {
    font-size: 12px;
    max-width: 70px;
  }
}
@media (max-width: 501px) and (min-width: 335px) {
  /*----------- Navbar -------------*/
  #navButtonHomeDeviceShow {
    font-size: 100%;
    min-width: 10%;
    margin-left: 5%;
  }

  #navButtonUsersDeviceShow {
    font-size: 100%;
    min-width: 10%;
  }

  #navButtonDomainsDeviceShow {
    font-size: 100%;
    min-width: 10%;
  }
  #navButtonActiveDeviceShow {
    font-size: 100%;
    min-width: 10%;
  }

  #profileIcon {
    position: relative;
  }
  #logoutButtonDeviceShow {
    position: relative;
    float: left;
    right: 120%;
    border: 0;
    width: 10%;
    background-color: #20262e;
    position: relative;
    top: 0%;
  }

  /*----------- Device Name -------------*/
  .topCard {
    left: 0vw;
    min-width: 80%;
    height: 80vh;
    max-height: 600px;
    min-height: 600px;
  }

  .deviceHeaderPlace {
    margin-left: 2vw;
  }

  #exportButtonId {
    margin-top: -5%;
    width: 40%;
    margin-left: 4vw;
  }
  /*----------- Last GPS Coordinates -------------*/
  #lastCoords {
    margin-top: 20px;
    padding-bottom: 10%;
    position: relative;
    margin-left: -35vw;
    min-width: 80%;
  }
  #gpsDevinfo {
    width: 92.5%;
  }
  /*----------- Battery and Signal -------------*/

  #deviceInfo {
    margin-left: -60vw;
    width: 89%;
    margin-top: -10px;
    padding-bottom: 1%;
    position: relative;
    margin-bottom: 50px;
  }
  /*----------- DeviceShow -------------*/
  .deviceShowBackGround {
    height: 400%;
    width: 100%;
  }

  /*----------- OuterActionbar -------------*/
  .outerActionBar {
    height: 400px;
    position: relative;
    width: 93%;
    margin-left: 15px;
    margin-top: 0px;
    margin-bottom: 0%;
  }

  /*----------- Timezone button -------------*/
  #timeZoneId {
    min-width: 100px;
    max-width: 130px;
    font-size: 100%;
    margin-top: 0px;
    left: 10px;
    position: absolute;
  }

  /*----------- Row number button -------------*/
  #pageSizeButton {
    min-width: 100px;
    max-width: 130px;
    margin-top: 0px;
    left: -5px;
    font-size: 100%;
    position: absolute;
  }
  #timeAndPage {
    top: 60%;
  }
  /*----------- Refresh label and switch -------------*/
  #refreshLabel {
    position: absolute;
    margin-top: 10px;
    left: 150px;
  }
  .refreshSwitch {
    position: absolute;
    top: 30px;
    left: 220px;
  }

  /*----------- Follow label and switch -------------*/
  .followLabel {
    position: absolute;
    top: 100px;
    left: 150px;
  }

  .followSwitch {
    position: absolute;
    top: 95px;
    left: 220px;
  }

  /*----------- Errors label and switch -------------*/
  .errorsLabel {
    position: absolute;
    top: 180px;
    left: 20px;
  }
  .errorsSwitch {
    position: absolute;
    top: 175px;
    left: 90px;
  }
  /*----------- Filter label and switch -------------*/
  .filterLabel {
    position: absolute;
    top: 250px;
    left: 20px;
  }
  .filterSwitch {
    position: absolute;
    top: 245px;
    left: 90px;
    margin-bottom: 60%;
  }

  /*----------- Error switch active -------------*/
  #errorInput {
    top: 170px;
    left: 160px;
    position: absolute;
  }

  .errorButton {
    top: 170px;
    left: 250px;
    position: absolute;
  }

  /*----------- Filter switch active -------------*/
  #startText {
    position: absolute;
    margin-top: 270px;
    left: 20px;
  }

  #endText {
    position: absolute;
    margin-top: 260px;
    left: 20px;
  }

  #startDate {
    left: 140px;
    margin-top: 265px;
    position: absolute;
  }

  #endDate {
    left: 140px;
    margin-top: 260px;
    position: absolute;
  }
  .dateButton {
    left: 245px;
    margin-top: 320px;
    position: absolute;
  }
  /*----------- Info button -------------*/
  #infoButton {
    left: 300px;
    top: -20px;
  }
  #infoDropdown {
    width: 300px;
    color: white;
  }
  /*----------- Map and table -------------*/
  #mapAndTable {
    display: inherit;
    margin-top: 0%;
    margin-bottom: 0%;
    bottom: 0px;
  }
  #mapContainer {
    width: 87%;
    position: relative;
    margin-left: -10px;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .pagingBarMobile {
    display: block;
    position: relative;
    top: 0px;
    margin-bottom: 0%;
    bottom: 0px;
  }

  .my-custom-scrollbar {
    max-height: 750px;
  }
  .pagingBarBottom {
    left: -2%;
    font-size: 67%;
    margin-bottom: 10px;
    margin-top: 0px;
    margin-left: 3%;
    position: relative;
  }
  #smallMap {
    width: 280px;
  }
  .map {
    width: 90%;
    position: relative;
    left: 1%;
    margin-bottom: 5%;
  }
  .table {
    height: 600px;
    margin-bottom: 0%;
    bottom: 0px;
    width: 120%;
    font-size: 0.8rem;
  }

  #mapAndTable {
    width: 90%;
    left: 0%;
    position: relative;
    border-collapse: collapse;
    margin-bottom: 0%;
    bottom: 0vh;
  }
  .tableCard {
    width: 95%;
    position: relative;
    margin-left: -1%;
    margin-bottom: 0%;
    bottom: 0px;
  }
  #mobileTableHeads {
    display: inline;
  }

  .flexCheckDefault {
    width: 70%;
    position: relative;
    left: 2%;
    bottom: 5%;
  }
  #tableCardId {
    width: 87%;
    margin-left: 12px;
    margin-top: 0%;
    position: relative;
    bottom: 0px;
    min-height: 600px;
  }
  #mapid {
    width: 100%;
    margin-bottom: 5%;
  }

  /*----------- Hide -------------*/
  .pagingBar {
    display: none;
  }
  #mobileTableHeads {
    display: none;
  }
  .pagingBarMobile {
    display: none;
  }
  #hiddenTitle {
    display: none;
  }
  .navText {
    display: none;
  }
  .usernameText {
    display: none;
  }
  #desktopTableHeads {
    display: none;
  }
  #hideError:checked {
    border: 1px solid #42ba85;
    box-shadow: none;
  }

  #hideFilter:checked {
    border: 1px solid #42ba85;
    box-shadow: none;
  }
}

@media (max-width: 335px) {
  /*----------- Navbar -------------*/
  #navButtonHomeDeviceShow {
    font-size: 100%;
    min-width: 10%;
    margin-left: 0%;
    font-size: 80%;
  }

  #navButtonUsersDeviceShow {
    font-size: 100%;
    min-width: 10%;
    margin-left: 0%;
    font-size: 80%;
  }

  #navButtonDomainsDeviceShow {
    font-size: 100%;
    min-width: 10%;
    margin-left: 0%;
    font-size: 80%;
  }
  #navButtonActiveDeviceShow {
    font-size: 100%;
    min-width: 10%;
    margin-left: 0%;
    font-size: 80%;
  }

  #profileIcon {
    position: relative;
  }
  #logoutButtonDeviceShow {
    position: relative;
    float: left;
    right: 100%;
    border: 0;
    width: 10%;
    background-color: #20262e;
    position: relative;
    top: 0%;
  }

  /*----------- Device Name -------------*/
  .topCard {
    left: 0vw;
    min-width: 80%;
    height: 80vh;
    max-height: 600px;
    min-height: 600px;
  }

  .deviceHeaderPlace {
    margin-left: 2vw;
  }

  #exportButtonId {
    margin-top: -5%;
    width: 40%;
    margin-left: 4vw;
    min-width: 150px;
  }
  /*----------- Last GPS Coordinates -------------*/
  #lastCoords {
    margin-top: 20px;
    padding-bottom: 10%;
    position: relative;
    margin-left: -35vw;
    min-width: 80%;
  }
  #gpsDevinfo {
    width: 92.5%;
  }
  /*----------- Battery and Signal -------------*/

  #deviceInfo {
    margin-left: -60vw;
    width: 89%;
    margin-top: -10px;
    padding-bottom: 1%;
    position: relative;
    margin-bottom: 50px;
  }
  /*----------- DeviceShow -------------*/
  .deviceShowBackGround {
    height: 400%;
    width: 100%;
  }

  /*----------- OuterActionbar -------------*/
  .outerActionBar {
    height: 700px;
    position: relative;
    width: 93%;
    margin-left: 15px;
    margin-top: 0px;
    margin-bottom: 0%;
  }

  /*----------- Timezone button -------------*/
  #timeZoneId {
    min-width: 100px;
    max-width: 130px;
    font-size: 100%;
    margin-top: 0px;
    left: 10px;
    position: absolute;
  }

  /*----------- Row number button -------------*/
  #pageSizeButton {
    min-width: 100px;
    max-width: 130px;
    margin-top: 0px;
    left: -5px;
    font-size: 100%;
    position: absolute;
  }
  #timeAndPage {
    top: 60%;
  }
  /*----------- Refresh label and switch -------------*/
  #refreshLabel {
    position: absolute;
    top: 160px;
    left: 20px;
  }
  .refreshSwitch {
    position: absolute;
    top: 155px;
    left: 90px;
  }

  /*----------- Follow label and switch -------------*/
  .followLabel {
    position: absolute;
    top: 230px;
    left: 20px;
  }

  .followSwitch {
    position: absolute;
    top: 225px;
    left: 90px;
  }

  /*----------- Errors label and switch -------------*/
  .errorsLabel {
    position: absolute;
    top: 300px;
    left: 20px;
  }
  .errorsSwitch {
    position: absolute;
    top: 295px;
    left: 90px;
  }
  /*----------- Filter label and switch -------------*/
  .filterLabel {
    position: absolute;
    top: 370px;
    left: 20px;
  }
  .filterSwitch {
    position: absolute;
    top: 365px;
    left: 90px;
  }

  /*----------- Error switch active -------------*/
  #errorInput {
    top: 440px;
    left: 20px;
    position: absolute;
  }

  .errorButton {
    top: 440px;
    left: 110px;
    position: absolute;
  }

  /*----------- Filter switch active -------------*/
  #startText {
    position: absolute;
    top: 510px;
    left: 20px;
  }

  #endText {
    position: absolute;
    top: 580px;
    left: 0px;
  }

  #startDate {
    left: 70px;
    top: 505px;
    position: absolute;
  }

  #endDate {
    left: 70px;
    top: 570px;
    position: absolute;
  }
  .dateButton {
    left: 20px;
    top: 640px;
    position: absolute;
  }
  /*----------- Info button -------------*/
  #infoButton {
    left: 200px;
    top: -20px;
  }
  #infoDropdown {
    width: 50vw;
    color: white;
  }
  /*----------- Map and table -------------*/
  #mapAndTable {
    display: inherit;
    margin-top: 0%;
    margin-bottom: 0%;
    bottom: 0px;
  }
  #mapContainer {
    width: 87%;
    position: relative;
    margin-left: -10px;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .pagingBarMobile {
    display: block;
    position: relative;
    top: 0px;
    margin-bottom: 0%;
    bottom: 0px;
  }

  .my-custom-scrollbar {
    max-height: 750px;
  }
  .pagingBarBottom {
    left: -2%;
    font-size: 0.4rem;
    margin-bottom: 10px;
    margin-top: 0px;
    margin-left: 3%;
    position: relative;
  }
  #smallMap {
    width: 280px;
  }
  .map {
    width: 90%;
    position: relative;
    left: 1%;
    margin-bottom: 5%;
  }
  .table {
    height: 500px;
    margin-bottom: 0%;
    bottom: 0px;
    width: 120%;
    font-size: 0.8rem;
  }

  #mapAndTable {
    width: 90%;
    left: 0%;
    position: relative;
    border-collapse: collapse;
    margin-bottom: 0%;
    bottom: 0vh;
  }
  .tableCard {
    width: 95%;
    position: relative;
    margin-left: -1%;
    margin-bottom: 0%;
    bottom: 0px;
  }
  #mobileTableHeads {
    display: inline;
  }

  .flexCheckDefault {
    width: 70%;
    position: relative;
    left: 2%;
    bottom: 5%;
  }
  #tableCardId {
    width: 87%;
    margin-left: 12px;
    margin-top: 0%;
    position: relative;
    bottom: 0px;
    min-height: 600px;
  }
  #mapid {
    width: 100%;
    margin-bottom: 5%;
  }

  /*----------- Hide -------------*/
  .pagingBar {
    display: none;
  }
  #mobileTableHeads {
    display: none;
  }
  .pagingBarMobile {
    display: none;
  }
  #hiddenTitle {
    display: none;
  }
  .navText {
    display: none;
  }
  .usernameText {
    display: none;
  }
  #desktopTableHeads {
    display: none;
  }
  #hideError:checked {
    border: 1px solid #42ba85;
    box-shadow: none;
  }

  #hideFilter:checked {
    border: 1px solid #42ba85;
    box-shadow: none;
  }
}
</style>
